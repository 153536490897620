import { Routes } from './nav.constant';

export const ContactList: UrlSet[] = [
  {
    iconClassName: 'bi-geo-alt',
    name: 'Hebbelstraße 1, 39108, Magdeburg',
    url: 'https://goo.gl/maps/XgwaxqzUybJ6Rvmr5',
  },
  {
    iconClassName: 'bi-telephone',
    name: '+49 (391) 402-1391',
    url: 'tel:493914021391',
  },
  {
    iconClassName: 'bi-phone',
    name: '+49 (162) 755-1821',
    url: 'tel:401627551821',
  },
  {
    iconClassName: 'bi-envelope',
    name: 'rsadowski@doorwayinternational.com',
    url: 'emailto:rsadowski@doorwayinternational.com',
  },
];

export const SocialMediaUrlSets: UrlSet[] = [
  {
    iconClassName: 'bi-instagram',
    name: 'Instagram',
    url: 'https://www.instagram.com/doorwayinternationalgbr/',
  },
  {
    iconClassName: 'bi-twitter',
    name: 'Twitter',
    url: 'https://twitter.com/doorwayinterna1',
  },
  {
    iconClassName: 'bi-facebook',
    name: 'Facebook',
    url: 'https://www.facebook.com/DoorwayInternationalGermany',
  },
];

export const NavigationUrlSets: UrlSet[] = [
  { name: 'Home', url: Routes.home },
  { name: 'About us', url: Routes.about },
  { name: 'Services', url: Routes.services },
  { name: 'Job board', url: Routes.jobBoard },
];

export const ResourceUrlSets: UrlSet[] = [
  { name: 'Blog', url: '/' },
  { name: 'Status', url: '/' },
];

export const TermsUrlSets: UrlSet[] = [
  { name: 'Terms and conditions', url: '/assets/pdf/TermsAndConditions.pdf' },
  { name: 'Privacy Policy', url: '/assets/pdf/PrivacyPolicy.pdf' },
];
