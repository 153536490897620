/* eslint-disable react/button-has-type */
import MetaHelmet from '../../components/meta-helmet/MetaHelmet';
import './HomePage.scss';
import IntroductionSliderSection from './section/IntroductionSliderSection';
import ServiceIntroSection from './section/ServiceIntroSection';
import SuccessStorySection from './section/SuccessStorySection';

const HomePage = () => {
  return (
    <main className="home-page container-fluid px-0">
      <MetaHelmet />
      <IntroductionSliderSection />
      <div className="container">
        <ServiceIntroSection />
        <SuccessStorySection />
      </div>
    </main>
  );
};

export default HomePage;
