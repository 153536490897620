import { Link, NavLink, useLocation, useSearchParams } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import { CircleFlag } from 'react-circle-flags';

import { NavBarRoutes } from '../../constants/nav.constant';
import './NavBar.scss';
import { FC, useEffect, useState } from 'react';
import i18n from '../../i18n/i18n';

enum LANGUAGE {
  ENGLISH = 'en',
  GERMAN = 'de',
}
enum FLAG {
  ENGLISH = 'uk',
  GERMAN = 'de',
}

const LANGUAGE_LIST = [LANGUAGE.ENGLISH, LANGUAGE.GERMAN];

const Flag: FC<{
  countryCode: FLAG;
  isSelected: boolean;
  onClick: () => void;
}> = ({ countryCode, isSelected, onClick }) => (
  <div
    className={
      'mx-1 cursor-pointer pb-1 ' +
      (isSelected ? 'flag-selected' : 'flag-not-selected')
    }
    onClick={onClick}
  >
    <CircleFlag countryCode={countryCode} height="30" />
  </div>
);
const NavBar = () => {
  const [showNavMenu, setShowNavMenu] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<LANGUAGE>(
    LANGUAGE.ENGLISH,
  );

  const location = useLocation();
  const [searchParams] = useSearchParams();

  i18n.on('languageChanged', (lng) =>
    LANGUAGE_LIST.includes(lng as LANGUAGE)
      ? setCurrentLanguage(lng as LANGUAGE)
      : i18n.changeLanguage(LANGUAGE.ENGLISH),
  );

  useEffect(() => {
    try {
      const lang = searchParams.get('lang');
      const lang2 = localStorage.getItem('i18nextLng');

      LANGUAGE_LIST.includes(lang as any as LANGUAGE)
        ? i18n.changeLanguage(lang as any as LANGUAGE)
        : LANGUAGE_LIST.includes(lang2 as any as LANGUAGE)
        ? i18n.changeLanguage(lang2 as any as LANGUAGE)
        : i18n.changeLanguage(LANGUAGE.ENGLISH);
    } catch (e) {}
  }, []);

  useEffect(() => {
    setShowNavMenu(false);
  }, [location]);

  return (
    <>
      <nav className="navbar position-fixed w-100 bg-white">
        <div className="container d-flex align-items-center align-items-lg-center justify-content-between w-100">
          <Link to="/">
            <Logo />
          </Link>
          <div>
            <div className="d-flex align-items-center">
              <button
                className="nav-collapse-button btn btn-outline-primary d-block d-lg-none ms-auto"
                type="button"
                onClick={() => setShowNavMenu(!showNavMenu)}
              >
                <i className="bi bi-list" />
              </button>
              <div className="d-none d-lg-block">
                {NavBarRoutes.map((link) => (
                  <NavLink
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    key={link.name}
                    to={link.url}
                  >
                    {link.name}
                  </NavLink>
                ))}
              </div>
              <div className="d-flex ms-3 pt-2">
                <Flag
                  countryCode={FLAG.ENGLISH}
                  isSelected={currentLanguage === LANGUAGE.ENGLISH}
                  onClick={() => i18n.changeLanguage(LANGUAGE.ENGLISH)}
                />
                <Flag
                  countryCode={FLAG.GERMAN}
                  isSelected={currentLanguage === LANGUAGE.GERMAN}
                  onClick={() => i18n.changeLanguage(LANGUAGE.GERMAN)}
                />
              </div>
            </div>
            {showNavMenu && (
              <ul className="navbar-nav my-2 my-lg-0 me-0 d-flex d-block d-lg-none">
                {NavBarRoutes.map((link) => (
                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) =>
                        'nav-link ' + (isActive ? 'active' : '')
                      }
                      key={link.name}
                      to={link.url}
                    >
                      {link.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
