export const ServicesRouteTag = {
  stemRecruitment: 'stem-recruitment',
  apprenticeshipRecruitment: 'apprenticeship-recruitment',
  nursingRecruitment: 'nursing-recruitment',
  resumeAndInterviewCoaching: 'resume-and-interview-coaching',
  careerDevelopmentCoaching: 'career-development-coaching',
  employerBrandingAndRecruitmentMarketing:
    'employer-branding-and-recruitment-marketing',
  events: 'events',
};

export const ServicesImageThumbMapper = {
  [ServicesRouteTag.stemRecruitment]: '/assets/images/service/STEM_SM.jpg',
  [ServicesRouteTag.apprenticeshipRecruitment]:
    '/assets/images/service/Apprentice_SM.jpg',
  [ServicesRouteTag.nursingRecruitment]:
    '/assets/images/service/Healthcare_SM.jpg',
  [ServicesRouteTag.resumeAndInterviewCoaching]:
    '/assets/images/service/Resume_SM.jpg',
  [ServicesRouteTag.careerDevelopmentCoaching]:
    '/assets/images/service/Career_SM.jpg',
  [ServicesRouteTag.employerBrandingAndRecruitmentMarketing]:
    '/assets/images/service/Branding_SM.jpg',
};

export const ServicesImageMapper = {
  [ServicesRouteTag.stemRecruitment]: '/assets/images/service/STEM.jpg',
  [ServicesRouteTag.apprenticeshipRecruitment]:
    '/assets/images/service/Apprentice.jpg',
  [ServicesRouteTag.nursingRecruitment]:
    '/assets/images/service/Healthcare.jpg',
  [ServicesRouteTag.resumeAndInterviewCoaching]:
    '/assets/images/service/Resume.jpg',
  [ServicesRouteTag.careerDevelopmentCoaching]:
    '/assets/images/service/Career.jpg',
  [ServicesRouteTag.employerBrandingAndRecruitmentMarketing]:
    '/assets/images/service/Branding.jpg',
};

export const ServicesRouteTagNameMapper = {
  [ServicesRouteTag.stemRecruitment]: 'job.STEM',
  [ServicesRouteTag.apprenticeshipRecruitment]: 'job.Apprentice',
  [ServicesRouteTag.nursingRecruitment]: 'job.Healthcare',
  [ServicesRouteTag.resumeAndInterviewCoaching]: 'job.Resume',
  [ServicesRouteTag.careerDevelopmentCoaching]: 'job.Career',
  [ServicesRouteTag.employerBrandingAndRecruitmentMarketing]: 'job.Branding',
};

const ServicesRoute = '/services';

const generateServiceRoute = (id: string): string => `${ServicesRoute}/${id}`;

export const Routes = {
  home: '/',
  about: '/about',
  services: ServicesRoute,
  stemRecruitmentService: generateServiceRoute(
    ServicesRouteTag.stemRecruitment,
  ),
  apprenticeshipRecruitmentService: generateServiceRoute(
    ServicesRouteTag.apprenticeshipRecruitment,
  ),
  nursingRecruitmentService: generateServiceRoute(
    ServicesRouteTag.nursingRecruitment,
  ),
  resumeAndInterviewCoachingService: generateServiceRoute(
    ServicesRouteTag.resumeAndInterviewCoaching,
  ),
  careerDevelopmentCoachingService: generateServiceRoute(
    ServicesRouteTag.careerDevelopmentCoaching,
  ),
  employerBrandingAndRecruitmentMarketingService: generateServiceRoute(
    ServicesRouteTag.employerBrandingAndRecruitmentMarketing,
  ),
  events: generateServiceRoute(ServicesRouteTag.events),
  jobBoard: '/job-board',
  contact: '/contact',
};

export const NavBarRoutes = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'About Us',
    url: Routes.about,
  },
  {
    name: 'Services',
    url: Routes.services,
  },
  {
    name: 'Job Board',
    url: Routes.jobBoard,
  },
  {
    name: 'Contact Us',
    url: Routes.contact,
  },
];
