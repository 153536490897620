import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Routes } from '../../../constants/nav.constant';

import './IntroductionSliderSection.scss';

const Data = [
  {
    imagePath:
      'https://images.pexels.com/photos/3957992/pexels-photo-3957992.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    text1:
      'Unlock Indian talent fluent in German up to B2 level—300+ candidates ready for FSJ/Azubis, Nurses roles! ',
    text2: 'Elevate your team with language-proficient professionals today!',
  },
  {
    imagePath:
      'https://images.pexels.com/photos/3778966/pexels-photo-3778966.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    text1: 'Unlock Your Dream Career in Germany: ',
    text2: 'Discover Opportunities, Excel, and Thrive!',
  },
  {
    imagePath:
      'https://images.pexels.com/photos/4481323/pexels-photo-4481323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    text1:
      'Warehouse workers with essential German skills for seamless operations ',
    text2:
      '— trust Doorway International! Enhance efficiency with our language-savvy recruits.',
  },
  {
    imagePath:
      'https://images.pexels.com/photos/346793/pexels-photo-346793.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    text1:
      'Visa assistance and legal checks streamlined with German consulates—seamless relocation guaranteed! ',
    text2: 'Navigate the complexities of relocation effortlessly.',
  },
  {
    imagePath:
      'https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    text1:
      'Global talent recruitment made easy—Doorway International connects you with skilled professionals worldwide! ',
    text2: ' Expand your workforce horizon with our expertise.',
  },
];

const IntroductionSliderSection = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <section className="introduction-slider-panel d-flex">
      <Carousel showArrows infiniteLoop autoPlay>
        {Data.map((data) => (
          <>
            <div className="item-container position-relative">
              <img
                alt="About"
                className="about-image"
                src={data.imagePath}
                // src={window.location.origin + '/assets/images/about-banner.webp'}
              />
              <div className="text-container h-100 d-flex align-items-center mt-5">
                <h2 className="text-center px-1 px-lg-5 mx-1 mx-lg-5 text-white">
                  {data.text1}
                  <span className="text-primary">{data.text2}</span>
                </h2>
              </div>
            </div>
          </>
        ))}
      </Carousel>
    </section>
  );
};

export default IntroductionSliderSection;
